 .studentpageNav{
    background-color: var(--bgcolor);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    
}
.navbarrightSidediv{
    margin-right: 15px;
}
.studentpageLogo{
    
    display: flex;
    justify-content: center;
    align-items: center;
   
   
}
.assmentlogo{
  width: 250px;
   padding-left: 10px;
}
@media only screen and (max-width:1300px) {
    .assmentlogo{
        width: 200px;
    }
    .navbarrightSidediv{
  
         padding:0 10px 0 0px;
    }

}
