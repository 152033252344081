.tableDivs{
    width: 100%;
  
    }
    .activeTableHeaders{
   
      width: 125px;
      height: 40px;
      color: #f2f2f2;
    }
    .activeHeadName{
      width: 100px;
      height: 40px;
      color: #f2f2f2;
    }
    .activeHeadNoOfQuestion{
      width: 10px;
      height: 40px;
      color: #f2f2f2;
    }
    .activeHeadSchedule{
      width: 30px;
      height: 40px;
      color: #f2f2f2;
    }
    .activeHeadAttendees{
      width: 30px;
      height: 40px;
      color: #f2f2f2;
    }
    .activeHeadActions{
      /* background-color: #FFB015; */
      width: 30px;
      height: 40px;
      color: #f2f2f2;
    }
    .activeTestNameTableData{
        height: 50px;
        width: 33%;
        text-align: center;
        background-color: #fff3e5;
      }
    .activeNoOfQuestionTableData{
        height: 50px;
        width: 13%;
        text-align: center;
      }
    .activeScheduleTimeTableData{
        height: 50px;
        width: 7%;
        text-align: center;
      }
    .activeEndTime{
      height: 50px;
      width: 8%;
      text-align: center;
    }
    .activeNoOfSTudentTableData{
        height: 50px;
        width: 10%;
        text-align: center;
      }
    .activeAttendeesTableData{
        height: 50px;
        width: 8%;
        text-align: center;
      }
      .Activeloading{
        margin-top: 120px;
      }
    
    .tableDivs tbody tr:nth-child(even) {
      background-color: #d9d9d9;
    }
    .resultOfActive{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    @media only screen and (min-width:800px) and 
    (max-width: 1200px) {
      .activeTableHeaders{
        width: 200px;
     
      }
    }

    @media only screen and (min-width: 600px) and (max-width: 800px) {
      
      .activeTableHeaders{
        width: 200px;
     
      }
      .activeTableHeaders{
      font-size: 13px; 
      }
      .activeHeadName{
        font-size: 13px; 
      }
      .activeHeadNoOfQuestion{
        font-size: 13px; 
      }
      .activeHeadSchedule{
        font-size: 13px; 
      }
      .activeHeadAttendees{
        font-size: 13px;
        width: 1px; 
      }
      .activeHeadActions{
        font-size: 13px; 
       
      } 
      .activeTestNameTableData{
        font-size:10px;
     
      }
      .activeNoOfQuestionTableData{
        font-size:10px;
      }
      .activeScheduleTimeTableData{
        font-size:10px;
      }
      .activeEndTime{
        font-size:10px;
      }
      .activeNoOfSTudentTableData{
        font-size:10px;
      }
      .activeAttendeesTableData{
        font-size:10px;
      }

    }

    @media only screen and (min-width:350px) and (max-width: 600px) {

      .activeTableHeaders{
        width: 900px;
        font-size:10px;
      }
      .activeHeadName{
        font-size:10px;
      }
      .activeHeadNoOfQuestion{
        font-size:10px;
      }
      .activeHeadSchedule{
        font-size:10px;
      }
      .activeHeadAttendees{
        font-size:10px;
      }
      .activeHeadActions{
        font-size:10px;
      }
      .activeTestNameTableData{
        font-size:10px;
      }
      .activeNoOfQuestionTableData{
        font-size:10px;
      }
      .historyScheduleTimeTableData{
        font-size:10px;
      }
      .activeScheduleTimeTableData{
        font-size:10px;
      }
      .activeEndTime{
        font-size:10px;
      }
      .activeNoOfSTudentTableData{
        font-size:10px;
      }
      .historyAttendeesTableData{
        font-size:10px;
      }
      .activeAttendeesTableData{
        font-size:10px;
      }

    }