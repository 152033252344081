.container {
  padding: 40px;
 
}
.slick-slide img {
  margin: auto;
}
.createNewTest {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.classAndStudentDetails {
  display: flex;
}
.classButton {
  display: flex;
  background-color: white;
}
.classandsection {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 32px;
}
.mathsandcreatetest {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 32px;
}
.sele {
 
  width: 110px;
}
@media (min-width: 1340px) and (max-width: 1430px) {

  .classAndStudentDetails {
    width: 41%;
  }
}

@media (min-width: 900px) and (max-width: 1340px) {
  .createNewTest {

    height: 120px;
  }
  .classAndStudentDetails {
    width: 54%;
  }
  .classButton {
    width: 85%;
  }
  .mathsandcreatetest {
    
    display: flex;
    justify-content: flex-end;
  }
  .sele {
   
    width: 200px;
  }
}
@media (min-width: 900px) and (max-width: 1199px) {
  .sele {
   
    width: 160px;
  }
}
@media (min-width: 780px) and (max-width: 899px) {
  .classandsection {
   
    width: 42%;
    display: flex;
    justify-content: space-between;
  }
  .mathsandcreatetest {
  
    width: 47%;
    display: flex;
    justify-content: space-between;
  }
}

 @media (min-width: 720px) and (max-width: 779px) {

  .classandsection {
   
    width: 44%;
    display: flex;
    justify-content: space-between;
  }
  .mathsandcreatetest {
   
    width: 47%;
    display: flex;
    justify-content: space-between;
  }
} 


@media (min-width: 680px) and (max-width: 720px) {
  
  .classandsection {
  
    width: 44%;
    display: flex;
    justify-content: space-between;
  }
  .mathsandcreatetest {
  
    width: 49%;
    display: flex;
    justify-content: space-between;
  }

}

@media (min-width: 640px) and (max-width: 680px) {

  .classandsection {
   
    width: 45%;
    display: flex;
    justify-content: space-between;
  }
  .mathsandcreatetest {
  
    width: 50%;
    display: flex;
    justify-content: space-between;
  }

}
@media (min-width: 625px) and (max-width: 639px) {
  .classandsection {
    
    width: 45%;
    display: flex;
    justify-content: space-between;
  }
  .mathsandcreatetest {
   
    width: 51%;
    display: flex;
    justify-content: space-between;
  }

}


@media (min-width: 608px) and (max-width: 624px) {
  .classandsection {
  
    width: 45%;
    display: flex;
    justify-content: space-between;
  }
  .mathsandcreatetest {
  
    width: 52%;
    display: flex;
    justify-content: space-between;
  }

}


@media (min-width: 600px) and (max-width: 607px) {
  .classandsection {
   
    width: 46%;
    display: flex;
    justify-content: space-between;
  }
  .mathsandcreatetest {

    width: 53%;
    display: flex;
    justify-content: space-between;
  }

}

@media (min-width: 300px) and (max-width: 599px) {
  .classandsection {
    display: flex;
    justify-content: space-between;
    width: 100%;

    height: 13vh;
    margin-top: 0px;
  }
  .mathsandcreatetest {
    width: 100%;
     margin-top: -6px;
    height: 11vh;
  }
  .sele {
    width: 100%;
  }
 
}