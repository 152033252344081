 .tableDivs{
    width: 100%;
    }
    .historyTableHeaders{
      width: 33%;
      height: 40px;
      color: #f2f2f2;
    }
    .histroyHeadName{
      width: 20%;
      height: 40px;
      color: #f2f2f2;
    }
    .histroyHeadNoOfQuestion{
      width: 15%;
      height: 40px;
      color: #f2f2f2;
    }
    .histroyHeadSchedule{
      width: 15%;
      height: 40px;
      color: #f2f2f2;
    }
    .histroyHeadAttendees{
      width: 20%;
      height: 40px;
      color: #f2f2f2;
    }
    .histroyHeadActions{
      width: 10px;
      height: 40px;
      color: #f2f2f2;
    }
    .historyTestNameTableData{
      height: 50px;
      text-align: center;
     background-color: #fff3e5; 
  
    }
    .historyNoOfQuestionTableData{
      height: 50px;
      width: 13%;
      text-align: center;
    }
    .historyScheduleTimeTableData{
      height: 50px;
      width: 10%;
      text-align: center;
    }
    .historyNoOfSTudentTableData{
      height: 50px;
      width:10%;
      text-align: center;
    }
    .historyAttendeesTableData{
      height: 50px;
      width:10%;
      text-align: center;
    }
    .tableDivs tbody tr:nth-child(even) {
      background-color: #d9d9d9;
    }
    .historyEditBtn{
      display: flex;
      background-color:#2ab9ec;
      width: 95px;
      height: 30px;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      color: white;
      gap: 5px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      font-size: 15px;
      cursor: pointer;
      margin-left: 35%;
      
    }
    .historyTableDatas{
      height: 50px;
      text-align: center;
    } 
    .resultOfHistroy{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .Historyloading{
      margin-top: 120px;
    }
    @media only screen and (min-width:800px) and (max-width: 1300px) {
      .historyTestNameTableData{
        height: 50px;
        width: 9%;
        text-align: center;
      }
       
    }
    @media only screen and  (max-width: 1000px) {
    .historyEditBtn{
      margin-left:30% ;
    }
  
  }

  @media only screen and  (max-width: 850px) {

    .historyEditBtn{
      margin-left:20% ;
    }

  }
    @media only screen and (min-width:800px) and 
    (max-width: 1200px) {
      .historyTableHeaders{
        width: 35%;
      }
      .histroyHeadName{
        width: 20%;
        height: 40px;
        color: #f2f2f2;
      }
      .histroyHeadNoOfQuestion{
        width: 10%;
        height: 40px;
        color: #f2f2f2;
      }
      .histroyHeadSchedule{
        width: 10%;
        height: 40px;
        color: #f2f2f2;
      }
      .histroyHeadAttendees{
        width: 20%;
        height: 40px;
        color: #f2f2f2;
      }
    }

    @media only screen and (min-width: 600px) and (max-width: 800px) {
      
      .historyViewEditBtn{
        display: none;
      }
      .historyEditBtn{
      
        width: 0px;
        height: 0px;
        margin-left: 45px;
      }
      .historyRedEyeIcon{
        color: #2ab9ec;
      }
      .historyTableHeaders{
      font-size: 13px; 
      width: 38%;
      }
      .histroyHeadName{
        font-size: 13px;
       
        width: 13%;
      }
      .histroyHeadNoOfQuestion{
        font-size: 13px; 
      
        width: 10%;
      }
      .histroyHeadSchedule{
        font-size: 13px; 
        width: 9%;
      }
      .histroyHeadAttendees{
        font-size: 13px;
        width: 10%;
      }
      .histroyHeadActions{
        font-size: 13px; 
       
      } 
      .historyTestNameTableData{
        font-size:14px;
     
      }
      .historyNoOfQuestionTableData{
        font-size:14px;
      }
      .historyScheduleTimeTableData{
        font-size:14px;
      }
      .historyAttendeesTableData{
        font-size:14px;
      }
      .historyRedEyeIcon{
        font-size: 20px !important;
      }

    }

    @media only screen and (min-width:370px) and (max-width: 600px) {

      .historyTableHeaders{
        width: 32%;
        font-size:10px;
      }
      .histroyHeadName{
        font-size:10px;
        width: 10%;
      }
      .histroyHeadNoOfQuestion{
        font-size:10px;
        width: 10%;
      }
      .histroyHeadSchedule{
        font-size:10px;
        width: 9%;
      }
      .histroyHeadAttendees{
        font-size:10px;
        width: 8%;
      }
      .histroyHeadActions{
        font-size:10px;
      }
       
      .historyViewEditBtn{
        display: none;
      } 
 
      .historyEditBtn{
      
        width: 0px;
        height: 0px;
    
      }
      .historyRedEyeIcon{
        color: #2ab9ec;
      }
      .historyTestNameTableData{
        font-size:12px;
      }
      .historyNoOfQuestionTableData{
        font-size:12px;
      }
      .historyScheduleTimeTableData{
        font-size:12px !important;
      
      }
      .historyAttendeesTableData{
        font-size:12px !important;
      }
      .historyRedEyeIcon{
        font-size: 17px !important;
        margin-left: 23px;
      }

    }
    
  