.menu-active {
    overflow: hidden;
  }
 .assessmentImg{
    width: 70px;
    margin-top: 20px; 
}
.heading{
 display: flex; 
 justify-content: space-between;
 padding: 0px 30px;
 width: 96.5%;
  align-items: center;
  border-bottom: 1px solid;
  padding-bottom: 3px;
margin-top: -3.5vh;
}
.container{
    display: flex;   
}
.tcsmobiles,
tcsbars {
  color: #ffff;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}  

  /* <--- Appbar Style --> */
   
.app{
    width: 50%;
}
.mainAppbar{
    background-color: white;
    display: flex;
    justify-content: center;
   width: 100%;
}
.linkClass{
    font-weight: bold;
    text-decoration: none;
    color: #000000;
    cursor: pointer;
}
.linkClas{
    font-weight: bold;
    text-decoration: none;
    color: #000000;
    cursor: pointer;
   
}
 .linkClass:hover{
    
    color:#8A45E4;
} 
.linkClas:hover{
    
    color:#8A45E4;
} 
.landinappBar{
    display: flex;
    background-color: white;
    justify-content: space-between;
    padding: 5px;
    height: 60px;
   width: 75%;
    align-items: center;
}
.landingappLogo{
  
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
   
  
}
.landingappbuttonDiv{

    width: 600px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.landingappnetcomLogo{
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.netcomlogos{
    width: 60px;
    height: 60px;
}

 @media only screen and (min-width: 768px) and (max-width: 1024px) {

    .landingappbuttonDiv{
     width: 550px;
     display: flex;
     justify-content: space-between;
     align-items: center;
     
     } 
     .netcomlogos{
        width: 55px;
        height: 55px;
       
    }
    .landinappBar{
   
        width: 97%;
       
    }
} 

@media only screen and (min-width: 480px) and (max-width: 767px) {

     .netcomlogos{
        width: 50px;
        height: 50px;
    } 
    .landingappbuttonDiv{
        width: 350px;
    } 
    .linkClass{
        font-size: 13px;
    }
    .linkClas{
        font-size: 13px;
    }
    .landinappBar{
   
        width: 97%;
       
    }
  
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
     .netcomlogos{
        width: 40px;
        height: 40px;
    }
    .landingappnetcomLogo{
        display: none;
    }
     .tcsmobiles{
        display: block;
        color: #000000;
        width: 50px;
        display: flex;
        justify-content: flex-end;
    }
    .tcsmobiles.tcsbars{
        display: block;
        color: #000000;
        transition: 0.9s ease-in-out;
        font-size: 26px;
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .tcsbars{
       
        color: #000000;
        width: 40px;
        height: 40px;
    }
    .landingappbuttonDiv{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: fixed;
        top: 72px;
        right: -610px;
        width: 100%;
        height: 100%;
        background-color:#282a35;
        box-shadow: 0 40px 60px rgba(0, 0, 0, 0);
        transition: 0.5s ease-in;
        padding: 40px 10 0 10px;
        font-size: 10px;
        gap: 50px;
    }
    .landingappbuttonDiv.active{
        right: 0px;
    }
    .landinappBar{
        background-color: #ffff;
        display: flex;
      
        align-items: center;
        width: 90%;
     
      }
      .landingappLogo{
          width: 50px;
          display: flex;
          justify-content: flex-start;
      }
      .color{
       margin-top: 5px;
      }
      .linkClass{
          margin-left: 4px;
          font-size: 13px;
          color: white;
      }
      .linkClas{
        margin-left: 4px;
        font-size: 13px;
        color: white;
        margin-top: 43px;
    }
    
}

@media screen and (max-width: 319px) {
 
    .landingappnetcomLogo{
        display: none;
    }
     .tcsmobiles{
        display: block;
        color: #000000;
        width: 50px;
        display: flex;
        justify-content: flex-end;
    }
    .tcsmobiles.tcsbars{
        display: block;
        color: #000000;
        transition: 0.9s ease-in-out;
        font-size: 26px;
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .tcsbars{
       
        color: #000000;
  
    }
    .landingappbuttonDiv{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: fixed;
        top: 70px;
        right: -610px;
        width: 100%;
        height: 100%;
        background-color:#282a35;
        box-shadow: 0 40px 60px rgba(0, 0, 0, 0);
        transition: 0.5s ease-in;
        padding: 40px 10 0 10px;
        font-size: 10px;
        gap: 50px;
    }
    .landingappbuttonDiv.active{
        right: 0px;
    }
    .landinappBar{
        background-color: #ffff;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .landingappLogo{
          width: 50px;
          display: flex;
          justify-content: flex-start;
      }
      .color{
       margin-top: 5px;
      }
      .linkClass{
          margin-left: 4px;
          font-size: 14px;
          color: white;
      }
      .linkClas{
        margin-left: 4px;
        font-size: 14px;
        color: white;
    }

}