/* <--- Content Style --> */
.getstarted {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.getstartedletter {
  display: flex;
  flex-direction: column;
  width: 60%;
}
.lettersize {
  font-weight: 700;
  font-size: 45px;
  text-align: justify;
  width: 100%;
  font-family: poppins;
}
.btncolor {
  height: 77px;
  width: 340px;
  border-radius: 150px;
  cursor: pointer;
}
.btnarrow {
  display: flex;
  justify-content: space-between;
  width: 400px;
  border-radius: 150px;
  align-items: center;
  font-size: 35px;
  cursor: pointer;
}
.containerDiv {
  width: 75%;
}
.firstcontainerDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top:76px;
}

/*<--- About --->*/
.h1font {
  font-family: poppins;
  margin-top: 1px;
}
.abouts {
  height: 65px;
}
.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 18px;
}
.aboutcontent {
  font-size: 35px;
  text-align: justify;
  font-family: poppins;
  margin-top: 0px;
}

/* <--feature and planet--> */
.planetend {
  display: flex;
  justify-content: flex-end;
}
.planetimg {
  display: flex;
  width: 54%;
  justify-content: space-between;

}

.featurehead {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: poppins;
  margin-top: 0px;
}
.planetimage {
  width: 180px;
}
/* <--Vector Line--> */
.vectorline {
  width: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.vectorlines {
  display: flex;
  justify-content: center;
}

/* <--Card1 Style--> */

.starbutt {
  background-color: #8170d6;
  border-color: #8170d6;
  border-radius: 10px;
}
.starbutt:hover {
  background-color: black;
}

.featurescard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card1 {
  background-color: #8a45e4;
  height: 280px;
  width: 100%;
  display: flex;

  align-items: center;
  border-radius: 0px 135px 135px 0px;
}

.cardmargin {
  margin-left: 25px;
}

.card1watchvideo {
  height: 180px;
  width: 225px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wathvideo {
  width: 145px;
  height: 150px;
}
.watchvideotext {
  display: flex;
  justify-content: center;
}
.watchevideotext1 {
  font-size: 15px;
  font-weight: 40px;
  color: #f5f5dc;
  font-family: poppins;
}
.cardwatchvideostext {
  color: #f5f5dc;
  width: 70%;
  font-size: 25px;
  font-family: poppins;
  margin-left: 20px;
  text-align: justify;
}
/* CARD2 STYLES */
.card2 {
  background-color: #8a45e4;
  height: 280px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 135px 0px 0px 135px;
}
.card2takeassessment {
  height: 180px;
  width: 220px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.takeassessment {
  width: 145px;
  height: 150px;
}
.takeassessmenttext {
  display: flex;
  justify-content: center;
}
.takeassessmenttext1 {
  font-size: 15px;
  font-weight: 40px;
  color: beige;
  font-family: poppins;
}
.card2takeassessmenttext {
  color: beige;
  width: 70%;
  font-size: 25px;
  font-family: poppins;
  text-align: justify;
}
.ourmotoimg {
  display: flex;
  justify-content: center;
  margin-top: 70px;
  flex-direction: column;
  align-items: center;
  font-family: poppins;
}
.ourmotoimgs {
  width: 100%;
}
.demovideohead {
}
/* <--Player Div--> */
.playerdiv {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
 
}
/* <--FOOTER DIV--> */

.demovideohead {
  display: flex;
  justify-content: center;
  font-family: poppins;
}
.socialicons {
  cursor: pointer;
  color: white;
  font-size: 42px !important;
}
.socialmedia {
  display: flex;
  width: 80%;
  justify-content: space-between;
  color: beige;
}
.socialicon {
  display: flex;
  font-size: 30px;
  width: 100%;
  color: beige;
}

.firstsocialicons {
  display: flex;
  flex-direction: column;
  width: 27%;
}
.address1 {
  width: 27%;
  color: beige;
  margin-top: 13px;
}
.address2 {
  width: 27%;
  color: beige;
  margin-top: 13px;
}
.addresshead {
  font-size: 24px;
  font-family: poppins;
  font-weight: bold;
}
.addresssize {
  font-size: 15px;
  font-family: poppins;
}
.netcomweb {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: beige;
  height: 20px;
   padding: 5px 0px; 
  width: 100%;
}
.netcom {
  font-size: 15px;
  margin-bottom: 2px;
}
.connect {
  font-family: poppins;
  font-size: 24px;
  font-weight: bold;
}
.footerholeDiv {
   background-color: #6549b5;
   display: flex;
   justify-content: center; 
  margin-top: 80px;
  flex-direction: column;
 align-items: center;
 width: 100%;

}
 .footerSecondHoleDiv{
  display: flex;
  justify-content: center;
  flex-direction: column; 
  align-items: center;
  width: 75%;
}  
.socialfoot {
  display: flex;
  justify-content: space-between;
  color: beige;
  width: 100%;
  height: 253px;
}
/* <--Resposive desing for content div--> */
/* <- CARD1 RESPOSIVE FOR FIRSR TWO MEDIA QUERY--> */
@media only screen and (min-width: 1300px) and (max-width: 1550px) {

  .card2 {
    height: 280px;
  }
  .ourmotoimg {
    margin-top: 55px;
  }
  .getstartedletter{
  margin-right: 10px;
  }
 
}
@media only screen and (min-width: 1200px) and (max-width: 1300px) {

  .card2 {
    height: 280px;
  }
  .ourmotoimg {
    margin-top: 55px;
  }
  .card2margin{
   
    margin-right: 7px;
  }
  .getstartedletter{
  margin-right: 10px;
  }
  .btncolor {
    height: 75px;
    width: 300px;
  }
  .btnarrow {
    font-size: 30px;
  }
 
}
@media only screen and (min-width: 1200px) and (max-width: 1553px) {
  .lettersize {
    font-weight: 700;
    font-size: 37px;
  }
  .contentimg {
    width: 400px;
  }
}
@media only screen and (min-width: 1145px) and (max-width: 1200px) {
  
  .lettersize {
    font-size: 34px;
    font-weight: 700;
  }
  .cardwatchvideostext{
    font-size:22px ;
  
  }
  .card2takeassessmenttext{
    font-size:22px ;
  }
  .card2margin{
   
    margin-right: 8px;
  }
  .contentimg {
    width: 350px;
  }
  .btncolor {
    height: 70px;
    width: 270px;

  }
  .btnarrow {
    font-size: 26px;
  }
  .aboutcontent {
    font-size: 35px;
    margin-top: -13px;
  }
  .abouts {
    height: 70px;
  }
  .planetimage {
    width: 140px;
  }
  .vectorline {
    width: 600px;
  }
  .card2 {
    height: 280px;
    display: flex;
  }
  .ourmotoimg {
    margin-top: 55px;
  }
  /*<--- react player  Resposive Style --->*/
  .playerdiv {
    margin-top: 20px;
  }
  /*<--- footer  Resposive Style --->*/
  .socialmedia {
    display: flex;
    width: 72%;

    justify-content: space-between;
    color: beige;
  }
  .socialicons {
    font-size: 45px !important;
  }
  .addresshead {
    font-size: 22px;
  }
  .addresssize {
    font-size: 16px;
  }
  .connect {
    font-size: 22px;
  }
  .socialfoot {
    height: 213px;
  }
  .getstartedletter{
  margin-right: 10px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1145px) {
  .lettersize {
    font-size: 28px;
   
  }
  .card2margin{
   
    margin-right: 13px;
  }
  .card2takeassessmenttext{
    font-size:20px ;
  }
  .cardwatchvideostext{
    font-size:20px ;
  }
  .contentimg {
    width: 340px;
  }
  .btncolor {
    height: 70px;
    width: 260px;
  }
  .btnarrow {
    font-size: 25px;
  }
  .aboutcontent {
    font-size: 33px;
  }
  .abouts {
    height: 54px;
  }
  .planetimage {
    width: 120px;
  }
  .vectorline {
    width: 500px;
  }
  .card2 {
    height: 280px;
  }
  .ourmotoimg {
    margin-top: 55px;
  }
  /*<--- react player  Resposive Style --->*/
  .playerdiv {
    margin-top: 10px;
  }

  /*<--- footer  Resposive Style --->*/
  .socialmedia {
    display: flex;
    width: 79%;

    justify-content: space-between;
    color: beige;
  }
  .socialicons {
    font-size: 40px !important;
  }
  .addresshead {
    font-size: 20px;
  }
  .addresssize {
    font-size: 13px;
  }
  .connect {
    font-size: 20px;
  }
  .socialfoot {
    height: 203px;
  }
  .getstartedletter{
  margin-right: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {

  .lettersize {
    font-size: 28px;
  }
  .contentimg {
    width: 310px;
  }
  .btncolor {
    height: 70px;
    width: 238px;
  }
  .btnarrow {
    font-size: 23px;
  }
  .aboutcontent {
    font-size: 31px;
    margin-top: -10px;
  }
  .abouts {
    height: 60px;
  }
  .planetimage {
    width: 100px;
  }

  /* <CARD 1 RESPONSIVE> */
  .card1 {
    height: 200px;
  }

  .card1watchvideo {
    height: 138px;
    width: 147px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .wathvideo {
    width: 147px;
    height: 137px;
  }
  .watchvideotext {
    display: flex;
    justify-content: center;
  }
  .watchevideotext1 {
    font-size: 10px;
    font-weight: 40px;
    color: beige;
    font-family: poppins;
  }
  .cardwatchvideostext {
    color: beige;
    width: 70%;
    font-size: 14px;
    font-family: poppins;
    margin-left: 20px;
  }
  .cardmargin {
    margin-left: 18px;
  }
  .vectorline {
    width: 400px;
  }
  /* <--CARD 2--> */

  .card2 {
    height: 200px;
  }
  .card2takeassessment {
    height: 138px;
    width: 147px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .takeassessment {
    width: 147px;
    height: 137px;
  }
  .takeassessmenttext {
    display: flex;
    justify-content: center;
  }
  .takeassessmenttext1 {
    font-size: 10px;
    font-weight: 40px;
    color: beige;
    font-family: poppins;
  }
  .card2takeassessmenttext {
    color: beige;
    width: 70%;
    font-size: 14px;
    margin-left: 14px;
    font-family: poppins;
  }
  .ourmotoimg {
    margin-top: 55px;
  }

  /*<--- react player  Resposive Style --->*/
  .playerdiv {
    margin-top: 7px;
  }

  /*<--- footer  Resposive Style --->*/
  .socialmedia {
    display: flex;
    width: 87%;

    justify-content: space-between;
    color: beige;
  }
  .socialicons {
    font-size: 35px !important;
  }
  .addresshead {
    font-size: 20px;
  }
  .addresssize {
    font-size: 13px;
  }
  .connect {
    font-size: 20px;
  }
  .socialfoot {
    height: 203px;
  }
  .getstartedletter{
  margin-right: 10px;
  }
  .containerDiv {
    width: 97%;
  }
}
@media only screen and (min-width: 480px) and (max-width: 768px) {
  .lettersize {
    font-size: 18px;
  }
  .contentimg {
    width: 230px;
  }
  .btncolor {
    height: 63px;
    width: 200px;
  }
  .btnarrow {
    font-size: 18px;
  }
  .aboutcontent {
    font-size: 20px;
  }
  .abouts {
    height: 58px;
   
  }
  .planetimg{
 
    width: 60%;
  }
  .planetimage {
    width: 80px;
  }
  /* <CARD 1 RESPONSIVE> */
  .card1 {
    height: 185px;
  }

  .card1watchvideo {
    height: 125px;
    width: 135px;
    background-color: white;
  }
  .wathvideo {
    width: 130px;
    height: 125px;
  }
  .watchvideotext {
    display: flex;
    justify-content: center;
  }
  .watchevideotext1 {
    font-size: 10px;
    font-weight: 40px;
    color: beige;
    font-family: poppins;
  }
  .cardwatchvideostext {
    color: beige;
    width: 68%;
    font-size: 14px;
    font-family: poppins;
    margin-left: 10px;
  }
  .cardmargin {
    margin-left: 18px;
  }
  .vectorline {
    width: 300px;
  }
  /* <--CARD 2--> */
  .card2 {
    height: 185px;
    display: flex;
    align-items: center;
    border-radius: 135px 0px 0px 135px;
  }
  .card2takeassessment {
    height: 125px;
    width: 133px;
  }
  .takeassessment {
    width: 130px;
    height: 125px;
  }
  .takeassessmenttext {
    display: flex;
    justify-content: center;
  }
  .takeassessmenttext1 {
    font-size: 10px;
  }
  .card2takeassessmenttext {
    width: 65%;
    font-size: 14px;
  }

  .card2margin {
    margin-right: 4px;
  }
  .ourmotoimg {
    margin-top: 55px;
  }
  .containerDiv {
    width: 97%;
   
  }
  .footerSecondHoleDiv{
    width: 97%;
  }
  /*<--- react player  Resposive Style --->*/
  .playerdiv {
    margin-top: 7px;
  }
  /*<--- footer  Resposive Style --->*/
  .footerholeDiv{
    width: 100%;
  }
  .socialmedia {
    display: flex;
    width: 100%;

    justify-content: space-between;
    color: beige;
  }
  .socialicons {
    font-size: 30px !important;
  }
  .addresshead {
    font-size: 18px;
  }
  .addresssize {
    font-size: 13px;
  }
  .connect {
    font-size: 18px;
  }
  .socialfoot {
    margin-top: 31px;
  }
  .netcom {
    font-size: 13px;
  }
  .socialfoot {
    height: 233px;
  }
  .getstartedletter{
  margin-right: 10px;
  }
  .contentimg{
    width: 200px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .containerDiv {
    width: 95%;
  }
  .lettersize {
    font-size: 20px;
  }
  .contentimg {
    width: 296px;
  }
  .btncolor {
    height: 45px;
    width: 150px;
  }
  .btnarrow {
    font-size: 11px;
  }
  .getstarted {
    display: flex;
    flex-direction: column;
  }
  .getstartedletter {
    width: 90%;
    display: flex;
    align-items: center;
  }
  .conttentimgDiv {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .aboutcontent {
    font-size: 14px;
    text-align: justify;
    font-family: poppins;
    margin-top: -7px;
  }
  .abouts {
    height: 52px;
  }
  .planetimage {
    width: 55px; 
  }
  .planetend {
    display: flex;
    justify-content: flex-end;
  }
  .planetimg {
    display: flex;
    width: 62%;
    justify-content: space-between;
    background-color: aqua;
  }
  /* <CARD 1 RESPONSIVE> */
  .card1 {
    height: 125px;
  }

  .card1watchvideo {
    height: 73px;
    width: 73px;
    background-color: white;
  }
  .wathvideo {
    width: 70px;
    height: 70px;
  }
  .watchevideotext1 {
    font-size: 8px;
    font-weight: 40px;
    color: beige;
    font-family: poppins;
  }
  .cardwatchvideostext {
    color: beige;
    width: 68%;
    font-size: 12px;
    font-family: poppins;
    margin-left: 10px;
  }
  .cardmargin {
    margin-left: 13px;
  }
  .vectorline {
    width: 150px;
  }
  .card2 {
    height: 125px;
    display: flex;
    align-items: center;
    border-radius: 135px 0px 0px 135px;
  }
  .card2takeassessment {
    height: 73px;
    width: 73px;
  }
  .takeassessment {
    width: 70px;
    height: 70px;
  }
  .takeassessmenttext {
    display: flex;
    justify-content: center;
  }
  .takeassessmenttext1 {
    font-size: 8px;
  }
  .card2takeassessmenttext {
    width: 65%;
    font-size: 10px;
  }
  .card2margin {
    margin-right: 4px;
  }
  .ourmotoimg {
    margin-top: 25px;
  }
  .ourmotoimgs {
    width: 98%;
  }
  /*<--- react player  Resposive Style --->*/
  .playerdiv {
    margin-top: 1px;
  }
  /*<--- footer  Resposive Style --->*/

  .footerholeDiv {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }
  .socialfoot {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    color: beige;
    height: 405px;
  }
  .firstsocialicons {
    width: 90%;
    margin-left: 10px;
  }
  .socialicon {
    width: 100%;
  }
  .address1 {
    width: 90%;
    color: beige;
    margin-left: 10px;
  }
  .address2 {
    width: 90%;
    color: beige;
    margin-left: 10px;
  }
  .netcom {
    font-size: 13px;
  }
  .connect {
    font-size: 18px;
  }
  .socialmedia {
    width: 60%;
  }
  .socialicons {
    font-size: 35px !important;
  }
  .addresshead {
    font-size: 18px;
  }
  .addresssize {
    font-size: 13px;
  }
  .netcomweb {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  .footerSecondHoleDiv{
    display: flex;
    justify-content: center;
    flex-direction: column; 
    align-items: center;
    width: 95%;
  } 
}
