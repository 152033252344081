.studentdashboardContainer {
  
    width: 350px;
    border-radius: 10px;
    background-color: #ffb015;
   
  }
  
  .studentdashboardDetail {
    display: flex;
    height: 100px;
  }
  
  .studentdasboardFirst {
    width: 160px;
   
    border-radius: 10px 0 0 10px; 
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-color: black;
    border-width: 1px;
  }
  
  .studentdasboardSecond {
    width: 340px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-style: solid;
    border-color: black;
    border-radius: 0px 10px 10px 0px;
    border-width: 1px 1px 1px 0;
  }
  
  .infoLine {
    height: 1px;
    background-color: black;
    width: 100%;
  }
  .infoContent {
    display: flex;
    width: 250px;
    height: 30px;
    font-size: 14px;
  }
  .studentsecDetails {
 
    width: 40%;
    margin-left: 10px;
    height: 20px;
    margin-top: 5px;
  }
  .studentDetails{
    width: 40%;
    display: flex;
    justify-content: flex-end;
   
    height: 20px;
    margin-top: 5px;
  }
  
  @media screen and (max-width: 576px) {
    .studentInfo {
      width: 70%;
    }
  
    .infoContent {
      font-size: 14px; 
    }
  }
  
