
@media screen and (max-width:1400px) {

    .topic
    {
        width: 50% !important;
        text-align: center;
    }
    
}

@media screen and (max-width:1300px) {
  .main
    {
                                                   
     height: 90vh !important;
    }
        
.topic
{
    text-align: center;
}   
}

@media screen and (max-width:1050px) {
    
.topic
{
    text-align: center;
}
}

@media screen and (max-width:1100px) {

    .topic
    {
        width: 60% !important;
        text-align: center;
    }
    
}

@media screen and (max-width:900px) {

    .topic
    {
        flex-direction: column;
        align-items: center;
    }
    
}

@media screen and (max-width:300) {
 
}