.firstdiv {
    height: 90vh;
    margin-top: 70px;
}

.seconddiv {
    width: 45%;
}
@media screen and (max-width:1300px) {
    .firstdiv {
        height: 75vh;
        margin-top: 40px;
    }
    .seconddiv {
        width: 50%;
    }
    .firstbtn {
        width: 30vh;
    }
}