 .tableDiv{
width: 100%;

} 
.tableHeader{
  width: 100px;
  height: 40px;
  color: #f2f2f2;
}
.tableHeader1{
  width: 100px;
  height: 40px;
  color: #f2f2f2;
}
.tableHeader2{
  width: 100px;
  height: 40px;
  color: #f2f2f2;
}
.fourthtableHeader{
  width: 25%;
  height: 40px;
  color: #f2f2f2;
}
.tableData{
  height: 50px;
  width: 100px;
  text-align: center;
}
.tableDatawithCalandar{
  height: 50px;
  width: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
align-items: center;
}
.tableDiv tbody tr:nth-child(odd) {
  background-color: #d9d9d9;
}
.editBtns{
  display: flex;
  background-color:#E86A33;
  width: 95px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: white;
  gap: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-size: 15px;
  cursor: pointer;
}
.deleteBtn{
  gap: 5px;
  display: flex;
  background-color:  #F33636;
  width: 95px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-size: 15px;
  cursor: pointer;
}
.viewBtn{
  gap: 5px;
  display: flex;
  background-color:#2ab9ec;
  width: 95px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-size: 15px;
  cursor: pointer;
}
.tableDatas{
  width: 99%;
  display: flex;
  gap: 48px;
  align-items: center;
  height: 50px;
  justify-content: center;
} 
.upCommingTest{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.Upcommingloading{
  margin-top: 120px;
}

@keyframes zoomIn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.icone {
  font-size: 24px;
  display: inline-block;
  animation: zoomIn 2s infinite;
}
.noDataFound{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dataFoundLetter{
  font-size: 32px;
  font-family: Poppins;
  color: #6b4696;
}


@media only screen and (max-width: 1024px) {
  .editBtns{
    width: 75px;
    height: 30px;
  }
  .deleteBtn{
    width: 75px;
    height: 30px;
  }
  .viewBtn{
    width: 75px;
    height: 30px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 800px) {
   
  .editandDelete{
    display: none;
  }
  .editBtns{
    width: 0px;
  }
  .deleteBtn{
    width: 0px;
  }
  .viewBtn{
    width: 0px;
  }
  .editBtnColor{
  color:#FFB015;
  }
  .deleteBtnColor{
    color: #F33636;
  }
  .viewBtnColor{
    color:#2ab9ec;
  }
  .tableHeader{
    font-size: 13px;
  }
  .fourthtableHeader{
      width: 60px;
      height: 40px;
      color: #f2f2f2;
      font-size: 13px;
      background-color: #FFB015;
  }
  .tableHeader1{
    font-size: 13px;
  }
  .tableHeader2{
    font-size: 13px;
  }
  .tableHeader1{
    width: 14%;
    height: 40px;
    color: #f2f2f2;
    font-size: 13px;
  }
  .tableData{
    font-size: 12px;
  }

}

@media only screen and (min-width: 350px) and (max-width: 600px) {
  .upCommingTest {
    overflow-x: auto;
  }

  .tableDiv {
    width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  
  }

  .tableHeader,
  .tableHeader1,
  .tableHeader2,
  .fourthtableHeader,
  .tableData {
    font-size: 12px;
    padding: 8px;
  }

  .editBtnColor{
    font-size: 20px !important; 
    color:#FFB015;
  }
  .deleteBtnColor{
    font-size: 20px !important;
    color: #F33636;
    margin-top:3px;
  }
  .viewBtnColor {
    font-size: 20px !important;
    color:#2ab9ec;
    margin-top:3px;
  }

  .editandDelete {
    font-size: 12px;
  }
  .editandDelete{
    display: none;
  }
  .editBtns{
    width: 0px;
  }
  .deleteBtn{
    width: 0px;
  }
  .viewBtn{
    width: 0px;
  }
  .tableDatas{
   
    gap: 27px;
    display: flex;
    align-items: center;
  
  }
}
