/* 

.fourtypography{
    position: absolute;
    font-size: 380px;
    opacity: 0.5;
  }
.fourtypography {
  font-size: 410px;
  position: absolute;
  margin-top: 0px;
     opacity: 0.1;
     bottom: -3%; 
     left: 33%;
}
.pagenotFoundMain {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.pagenotFoundSecondMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 39%;
  width: 100%;
}
.pagenotFoundTextDiv {
  font-size: 50px;
  font-weight: 700;
  font-family: Barlow Condensed;
  margin-top: 0px;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagenotFoundsecTextDiv {
  font-size: 29px;
  text-align: center;
  margin-top: 0px;
  font-family: Barlow Condensed;
  width: 35%;
}


@media screen and (min-width: 1500px) and (max-width: 1650px) {
   .pagenotFoundsecTextDiv{
    width: 40%; 
    
  } 
  .pagenotFoundTextDiv {
    width: 45%;
 
  }
  .fourtypography {
    bottom: -9%;
    left: 30%;
    
  }
}
@media screen and (min-width: 1380px) and (max-width: 1550px) {
  .fourtypography {
    bottom: -9%;
    left: 28%;
  }
}
@media screen and (min-width: 1315px) and (max-width: 1379px) {
  .fourtypography {
    bottom: -9%;
    left: 25%;
  }
}
@media screen and (min-width: 1250px) and (max-width: 1314px) {
  .fourtypography {
    bottom: -9%;
    left: 25%;
  }
  
}
@media screen and (min-width: 1100px) and (max-width: 1249px) {
  .fourtypography {
    bottom: -7%;
    left: 22%;
    font-size: 395px;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1099px) {
  .fourtypography {
    bottom: -6%;
    left: 19%;
    font-size: 389px;
  }
}
@media screen and (min-width: 900px) and (max-width: 999px) {
  .fourtypography {
    bottom: 2%;
    left: 20%;
    font-size: 345px;
  }
}
@media screen and (min-width: 800px) and (max-width: 899px) {
  .fourtypography {
    bottom: 2%;
    left: 16%;
    font-size: 345px;
  }

}
@media screen and (min-width: 700px) and (max-width: 799px) {
  .fourtypography {
    bottom: 2%;
    left: 12%;
    font-size: 345px;
  }
}
@media screen and (min-width: 600px) and (max-width: 699px) {
  .fourtypography {
    bottom: 25%;
    left: 25%;
    font-size: 210px;
  }

}
@media screen and (min-width: 500px) and (max-width: 599px) {
  .fourtypography {
    bottom: 25%;
    left: 17%;
    font-size: 210px;
  }
}
@media screen and (min-width: 400px) and (max-width: 499px) {
  .fourtypography {
    bottom: 25%;
    left: 16%;
    font-size: 200px;
  }

}
@media screen and (min-width: 374px) and (max-width: 399px) {
  .fourtypography {
    bottom: 25%;
    left: 7%;
    font-size: 200px;
  }

}


  @media screen and (min-width: 1250px) and (max-width: 1499px) {
    .pagenotFoundsecTextDiv {
      width: 45%;
    }
 
    .pagenotFoundTextDiv{
       
         width: 53%;
         font-size: 72px;
         
    }

  
  }
  @media screen and (min-width:1000px) and (max-width: 1249px) {
    .pagenotFoundsecTextDiv {
      width: 52%;
    
      font-size: 27px;
    }
   
    .pagenotFoundTextDiv{
        
         width: 63%;
         font-size: 70px;
         
    }
 

  }

  @media screen and (min-width:700px) and (max-width: 999px) {

    .pagenotFoundsecTextDiv {
      width: 62%;
     
      font-size: 22px;
    }
   
    .pagenotFoundTextDiv{
      
         width: 79%;
         font-size: 61px;
         
    }

  }

  @media screen and (min-width:500px) and (max-width: 699px) {

    .pagenotFoundsecTextDiv {
      width: 62%;
      font-size: 16px;
    }
    .pagenotFoundSecondMain {
    
      height: 35%;
    }
    .pagenotFoundTextDiv{
         width:85%;
         font-size: 40px;  
    }

  }

  
  @media screen and (min-width:374px) and (max-width:499px) {

    .pagenotFoundsecTextDiv {
      width: 63%;
      font-size: 14px;
    }
    .pagenotFoundSecondMain {
  
      height: 29%;
    }
    .pagenotFoundTextDiv{
        
         width:90%;
         font-size: 37px;
         
    }

  }  */






  
.fourtypography{
    position: absolute;
    font-size: 380px;
    opacity: 0.5;
  }
.fourtypography {
  font-size: 440px;
  position: absolute;
  margin-top: 0px;
     opacity: 0.1;
     bottom: -6%; 
     left: 32%;
}
.pagenotFoundMain {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.pagenotFoundSecondMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 39%;
  width: 100%;
}
.pagenotFoundTextDiv {
  font-size: 52px;
  font-weight: 700;
  font-family: Barlow Condensed;
  margin-top: 0px;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagenotFoundsecTextDiv {
  font-size: 29px;
  text-align: center;
  margin-top: 0px;
  font-family: Barlow Condensed;
  width: 31%;
}

@media screen and (min-width: 1500px) and (max-width: 1650px) {
   .pagenotFoundsecTextDiv{
    width: 37%; 
    
  } 
  .pagenotFoundTextDiv {
    width: 45%;
 
  }
  .fourtypography {
    bottom: -14%;
    left: 29%;
    
  }
}
@media screen and (min-width: 1380px) and (max-width: 1499px) {
  .fourtypography {
    bottom: -14%;
    left: 26%;
  }
  
}
@media screen and (min-width: 1315px) and (max-width: 1379px) {
  .fourtypography {
    bottom: -14%;
    left: 24%;
  }
}
@media screen and (min-width: 1250px) and (max-width: 1314px) {
  .fourtypography {
    bottom: -14%;
    left: 22%;
  }
  
}
@media screen and (min-width: 1200px) and (max-width: 1249px) {

  .fourtypography {
    bottom: -11%;
    left: 22%;
    font-size: 425px;
  }

}
 @media screen and (min-width: 1150px) and (max-width: 1199px) {
  .fourtypography {
    bottom: -11%;
    left: 21%;
    font-size: 424px;
  }
} 
@media screen and (min-width: 1100px) and (max-width: 1149px) {
  .fourtypography {
    bottom: -11%;
    left: 20%;
    font-size: 424px;
  }
} 


@media screen and (min-width: 1050px) and (max-width: 1099px) {
  .fourtypography {
    bottom: -11%;
    left: 19%;
    font-size: 424px;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1049px) {
  .fourtypography {
    bottom: -11%;
    left: 17%;
    font-size: 423px;
  }
}
@media screen and (min-width: 950px) and (max-width: 999px) {
  .fourtypography {
    bottom: -2%;
    left: 21%;
    font-size: 369px;
  }
}
@media screen and (min-width: 900px) and (max-width: 949px) {
  .fourtypography {
    bottom: -2%;
    left: 19%;
    font-size: 369px;
  }
}


@media screen and (min-width: 800px) and (max-width: 899px) {
  .fourtypography {
    bottom: -1%;
    left: 16%;
    font-size: 364px;
  }

}
@media screen and (min-width: 700px) and (max-width: 799px) {
  .fourtypography {
    bottom: 0.19%;
    left: 10%;
    font-size: 364px;
  }
}
@media screen and (min-width: 650px) and (max-width: 699px) {
  .fourtypography {
    bottom: 15%;
    left: 19%;
    font-size: 270px;
  }

}
@media screen and (min-width: 600px) and (max-width: 649px) {
  .fourtypography {
    bottom: 16%;
    left: 17%;
    font-size: 265px;
  }

}
@media screen and (min-width: 500px) and (max-width: 599px) {
  .fourtypography {
    bottom: 17%;
    left: 13%;
    font-size: 260px;
  }
}
@media screen and (min-width: 400px) and (max-width: 499px) {
  .fourtypography {
    bottom: 17%;
    left: 5%;
    font-size: 250px;
  }
  .pagenotFoundTextDiv{
        
    width:90%;
    font-size: 28px;
    
}

}
@media screen and (min-width: 374px) and (max-width: 399px) {
  .fourtypography {
    bottom: 26%;
    left: 8%;
    font-size: 200px;
  }
  .pagenotFoundTextDiv{
        
    width:95%;
    font-size: 23px;
    
}

}


  @media screen and (min-width: 1250px) and (max-width: 1499px) {
    .pagenotFoundsecTextDiv {
      width: 45%;
    }
 
    .pagenotFoundTextDiv{
       
         width: 53%;
         font-size: 52px;
         
    }

  
  }
  @media screen and (min-width:1000px) and (max-width: 1249px) {
    .pagenotFoundsecTextDiv {
      width: 52%;
      
      font-size: 27px;
    }
   
    .pagenotFoundTextDiv{
         width: 63%;
         font-size: 50px;    
    }
 

  }

  @media screen and (min-width:700px) and (max-width: 999px) {

    .pagenotFoundsecTextDiv {
      width: 62%;
     
      font-size: 22px;
    }
   
    .pagenotFoundTextDiv{
      
         width: 79%;
         font-size: 43px;
         
    }

  }

  @media screen and (min-width:500px) and (max-width: 699px) {

    .pagenotFoundsecTextDiv {
      width: 62%;
      font-size: 16px;
    }
    .pagenotFoundSecondMain {
    
      height: 35%;
    }
    .pagenotFoundTextDiv{
         width:80%;
         font-size: 30px; 
    }

  }

  
  @media screen and (min-width:374px) and (max-width:499px) {

    .pagenotFoundsecTextDiv {
      width: 63%;
      font-size: 14px;
    }
    .pagenotFoundSecondMain {
  
      height: 29%;
    }
   

  } 